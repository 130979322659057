import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../../../../src/common/components/Layout'
import { t } from '../../../../src/common/i18n'
import { SeoData } from '../../../../src/common/types/Seo'
import { MenuPrincipal } from '../../constants/menu'
import { useSelectedMenu } from '../../helpers/customHooks/useSelectedMenu'
import styles from './index.module.scss'

const count = (i: number) => [...Array(i)].map((_, i) => i)

const history = count(21).map((i) => ({
  year: t(`empresa.history.history${i + 1}.year`),
  text: t(`empresa.history.history${i + 1}.text`),
  img: t(`empresa.history.history${i + 1}.img`),
}))

interface Props {
  data: {
    seoData: SeoData
  }
}
const empresa = ({ data, ...props }: Props) => {
  useSelectedMenu(MenuPrincipal.HOME)
  return (
    <Layout selector={false} seoData={data.seoData}>
      <div className={styles.empresa}>
        <div className={styles.empresa_title_cont}>
          <h1 className={styles.empresa_title}>{t('empresa.title-1')}</h1>

          <p className={styles.empresa_text}>
            {t('empresa.text-1')}
            <strong>{t('empresa.text-2')}</strong>
            {t('empresa.text-3')} <strong>{t('empresa.text-4')} </strong>
            {t('empresa.text-5')}
          </p>
          <p className={styles.empresa_text}>{t('empresa.text-6')}</p>
          <p className={styles.empresa_text}>
            {t('empresa.text-7')} <strong>{t('empresa.text-8')}</strong>{' '}
            {t('empresa.text-9')}
            <strong>{t('empresa.text-10')}</strong> {t('empresa.text-11')}
            <strong> {t('empresa.text-12')}</strong>
          </p>
        </div>

        <div className={styles.timeline}>
          {history.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  index % 2 === 0
                    ? `${styles.container} ${styles.left}`
                    : `${styles.container} ${styles.right}`
                }>
                <div
                  className={
                    index % 2 === 0
                      ? `${styles.content_left} `
                      : `${styles.content_right} `
                  }>
                  <span className={styles.date}>{item.year}</span>
                  <span className={styles.text}>{item.text}</span>
                </div>
                <div className={styles.yellowdot} />
                <div
                  className={
                    index % 2 === 0
                      ? `${styles.img_left} `
                      : `${styles.img_right} `
                  }>
                  <img src={item.img} alt="empresa" title="" />
                </div>
              </div>
            )
          })}
        </div>
        <div className={styles.empresa_title_cont}>
          <h2 className={styles.empresa_title}>{t('empresa.title-2')}</h2>

          <p className={styles.empresa_text}>{t('empresa.text-2-1')}</p>
          <p>
            <strong>{t('empresa.text-2-2')}</strong>
          </p>
          <p className={styles.empresa_text}>
            {t('empresa.text-2-3')}
            <strong> {t('empresa.text-2-4')}</strong>
            {t('empresa.text-2-5')}
            <strong>{t('empresa.text-2-6')}</strong>
          </p>
          <p className={styles.empresa_text}>
            <strong>{t('empresa.text-2-7')}</strong>
          </p>
          <p className={styles.empresa_text}>{t('empresa.text-2-8')}</p>
          <p className={styles.empresa_text}>{t('empresa.text-2-9')}</p>
        </div>
        <div className={styles.empresa_cont_images}>
          <div className={styles.empresa_images}>
            <img
              src={'https://media.rodi.es/estaticas/actualidad/actualidad1.png'}
              alt="empresa"
              title="empresa"
              width="100%"
            />
          </div>
          <div className={styles.empresa_images}>
            <img
              src={'https://media.rodi.es/estaticas/actualidad/actualidad2.png'}
              alt="empresa"
              title="empresa"
              width="100%"
            />
          </div>
          <div className={styles.empresa_images}>
            <img
              src={'https://media.rodi.es/estaticas/actualidad/actualidad3.png'}
              alt="empresa"
              title="empresa"
              width="100%"
            />
          </div>
          <div className={styles.empresa_images}>
            <img
              src={'https://media.rodi.es/estaticas/actualidad/actualidad4.jpg'}
              alt="empresa"
              title="empresa"
              width="100%"
            />
          </div>
          <div className={styles.empresa_images}>
            <img
              src={'https://media.rodi.es/estaticas/actualidad/actualidad5.jpg'}
              alt="empresa"
              title="empresa"
              width="100%"
            />
          </div>
          <div className={styles.empresa_images}>
            <img
              src={'https://media.rodi.es/estaticas/actualidad/actualidad6.jpg'}
              alt="empresa"
              title="empresa"
              width="100%"
            />
          </div>
          <div className={styles.empresa_images}>
            <img
              src={'https://media.rodi.es/estaticas/actualidad/actualidad7.png'}
              alt="empresa"
              title="empresa"
              width="100%"
            />
          </div>
          <div className={styles.empresa_images}>
            <img
              src={'https://media.rodi.es/estaticas/actualidad/actualidad8.png'}
              alt="empresa"
              title="empresa"
              width="100%"
            />
          </div>
          <div className={styles.empresa_images}>
            <img
              src={'https://media.rodi.es/estaticas/actualidad/actualidad9.png'}
              alt="empresa"
              title="empresa"
              width="100%"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default empresa

export const pageQuery = graphql`
  query empresaAuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
